import { isNumber, isWorker, roundToDecimal, truncateUrl } from "./utils";

export class PageService {

    public hasSentPage = false;

    getPageEntry(): RM.PagePerformanceEntry {
        if (isWorker()) { return null; }
        if (this.hasSentPage) { return null; }

        var entry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
        var result: RM.PagePerformanceEntry = null;

        // NOTE Safari doesn't have a navigation entry, so we need to crawl the old Timings API
        if (!entry) {
            var timings = performance.timing;
            result = {
                url: truncateUrl(self.location.toString()),
                tempFullUrl: self.location.toString(),
                referrer: isWorker() ? "" : document.referrer,
                start: 0,
                unloadTime: roundToDecimal(timings.unloadEventEnd - timings.unloadEventStart),
                redirectTime: roundToDecimal(timings.redirectEnd - timings.redirectStart),
                workerTime: 0,
                dnsTime: roundToDecimal(timings.domainLookupEnd - timings.domainLookupStart),
                // For now, sslTime includes full TCP connection time. JUST sslTime looks like this:
                //sslTime: timings.secureConnectionStart ? timings.connectEnd - timings.secureConnectionStart : 0,
                sslTime: roundToDecimal(timings.connectEnd - timings.connectStart),
                serverTime: roundToDecimal(timings.responseEnd - timings.requestStart),
                firstByteTime: roundToDecimal(timings.responseStart - timings.navigationStart),
                blockingAssetLoadTime: roundToDecimal(timings.domInteractive - timings.responseEnd),
                domInteractive: roundToDecimal(timings.domInteractive - timings.navigationStart),
                duration: roundToDecimal(timings.domComplete - timings.navigationStart),
            };
        }
        else {
            result = {
                // Sometimes entry.name contains "document" because of a misunderstanding in the w3 spec,
                // but we want to use it if it's a URL, since the self location might have been pushState'd.
                url: truncateUrl((entry.name && entry.name.indexOf("http") === 0) ? entry.name : self.location.toString()),
                tempFullUrl: entry.name.indexOf("http") === 0 ? entry.name : self.location.toString(),
                referrer: isWorker() ? "" : document.referrer,
                start: roundToDecimal(entry.startTime),
                unloadTime: roundToDecimal(entry.unloadEventEnd - entry.unloadEventStart),
                redirectTime: roundToDecimal(entry.redirectEnd - entry.redirectStart),
                workerTime: (entry.workerStart === 0) ? 0 : roundToDecimal(entry.domainLookupStart - entry.workerStart),
                dnsTime: roundToDecimal(entry.domainLookupEnd - entry.domainLookupStart),
                // For now, sslTime includes full TCP connection time. JUST sslTime looks like this:
                //sslTime: entry.secureConnectionStart ? entry.connectEnd - entry.secureConnectionStart : 0,
                sslTime: roundToDecimal(entry.connectEnd - entry.connectStart),
                serverTime: roundToDecimal(entry.responseEnd - entry.requestStart),
                firstByteTime: roundToDecimal(entry.responseStart),
                blockingAssetLoadTime: isNumber(entry.domInteractive) ? roundToDecimal(entry.domInteractive - entry.responseEnd) : 0,
                domInteractive: isNumber(entry.domInteractive) ? roundToDecimal(entry.domInteractive) : 0,
                duration: roundToDecimal(entry.duration),
                pageSize: entry.transferSize,
                // Status isn't in the spec, but we see it in Chrome browsers.
                statusCode: entry["responseStatus"],
                proto: entry.nextHopProtocol,
                type: entry.type,
                decodedBodySize: entry.decodedBodySize,
                encodedBodySize: entry.encodedBodySize,
                deliveryType: entry["deliveryType"]
            };
        }

        return result;
    }

    sentPage() {
        this.hasSentPage = true;
    }
}