import { isNumber, roundToDecimal } from "./utils";

var ignoredOrigins = [
    "safeframe.googlesyndication.com",
    "chrome-extension:",
    "moz-extension:"
];

// how long to watch after pageLoad for resources.
const RESOURCE_CAPTURE_THRESHOLD = 5_000;

export class ResourceService {
    private hasSent: boolean = false;

    private static cachedResourceTimings: PerformanceResourceTiming[] = null;

    cacheResources() {
        if (this.hasSent) {
            return;
        }
        ResourceService.cachedResourceTimings = ResourceService.getAllResources();
    }

    static getAllResources(): PerformanceResourceTiming[] {
        var allResources = (ResourceService.cachedResourceTimings || []).concat(performance.getEntriesByType("resource") as PerformanceResourceTiming[]);
        var resourceHash = {};
        allResources = allResources.filter(resource => {
            if (!resource || ResourceService.shouldIgnore(resource)) {
                return false;
            }
            var resourceKey = resource.name + resource.startTime;
            if (resourceHash[resourceKey]) {
                return false;
            }
            resourceHash[resourceKey] = true;
            return true;
        });

        return allResources;
    }

    getPageResources(): RM.PageResourceEntry[] {
        if (this.hasSent) { return []; }

        var navEntry = performance.getEntriesByType("navigation")[0];
        if (!navEntry) { return []; }

        var blockingEndTime = navEntry.domInteractive;
        var clientEndTime = navEntry.duration;

        var allResources = ResourceService.getAllResources();

        var pageResourceEntries = allResources
            .map((pageResource): RM.PageResourceEntry => {
                var resourceType = this.getResourceType(pageResource);
                if (resourceType === "xhr") { return null; }
                if (pageResource.startTime >= clientEndTime + RESOURCE_CAPTURE_THRESHOLD) {
                    return null;
                }
                if (!pageResource.name || pageResource.name.startsWith("data:")) {
                    return null;
                }

                var stage = "postload";
                if (pageResource.startTime <= blockingEndTime) {
                    stage = "blocking";
                }
                else if (pageResource.startTime <= clientEndTime) {
                    stage = "client"
                }

                return {
                    url: pageResource.name,
                    type: resourceType,
                    stage: stage,
                    renderBlockingStatus: pageResource["renderBlockingStatus"],
                    proto: pageResource.nextHopProtocol,
                    start: isNumber(pageResource.startTime) ? roundToDecimal(pageResource.startTime) : null,
                    duration: isNumber(pageResource.duration) ? roundToDecimal(pageResource.duration) : null,
                    responseStatus: pageResource["responseStatus"],
                    transferSize: pageResource.transferSize,
                    decodedBodySize: pageResource.decodedBodySize,
                    encodedBodySize: pageResource.encodedBodySize,
                    deliveryType: pageResource["deliveryType"]
                };
            })
            .filter(resource => {
                return resource !== null;
            });

        return pageResourceEntries;


    }

    static shouldIgnore(resource: PerformanceResourceTiming): boolean {
        return ignoredOrigins.some(io => resource.name.toLowerCase().indexOf(io) >= 0);
    }

    getResourceType(resource: PerformanceResourceTiming): "css" | "img" | "font" | "script" | "video" | "xhr" | "iframe" | "other" {
        if (this.isCss(resource)) { return "css"; }
        if (this.isImage(resource)) { return "img"; }
        if (this.isFont(resource)) { return "font"; }
        if (this.isScript(resource)) { return "script"; }
        if (this.isVideo(resource)) { return "video"; }
        if (this.isXhr(resource)) { return "xhr"; }
        if (this.isIFrame(resource)) { return "iframe"; }
        return "other";
    }

    isImage(timing: PerformanceResourceTiming): boolean {
        if (timing.initiatorType === "img") {
            return true;
        }
        try {
            if (timing.initiatorType === "css" || timing.initiatorType === "link") {
                var imgExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg", ".raw", ".webp", ".heif", ".avif"];
                var pathname = new URL(timing.name).pathname.toLowerCase();
                return imgExtensions.some(imgExt => pathname.endsWith(imgExt));
            }
        }
        catch { }

        return false;
    }

    isScript(timing: PerformanceResourceTiming): boolean {
        if (timing.initiatorType === "script") {
            return true;
        }
        try {
            if (timing.initiatorType === "link" || timing.initiatorType === "other") {
                var pathname = new URL(timing.name).pathname.toLowerCase();
                return pathname.endsWith(".js");
            }
        }
        catch { }

        return false;
    }

    isVideo(timing: PerformanceResourceTiming): boolean {
        return timing.initiatorType === "video";
    }

    isXhr(timing: PerformanceResourceTiming): boolean {
        return timing.initiatorType === "fetch" || timing.initiatorType === "xmlhttprequest";
    }

    isIFrame(timing: PerformanceResourceTiming): boolean {
        return timing.initiatorType === "iframe";
    }

    isCss(timing: PerformanceResourceTiming): boolean {
        if (timing.initiatorType !== "link" && timing.initiatorType !== "css") {
            return false;
        }
        try {
            var pathname = new URL(timing.name).pathname;
            return (pathname.toLowerCase().indexOf("css") >= 0);
        }
        catch { }

        return false;
    }

    isFont(timing: PerformanceResourceTiming): boolean {
        if (timing.initiatorType !== "link" && timing.initiatorType !== "css" && timing.initiatorType !== "other") {
            return false;
        }
        try {
            var fontExtensions = [".woff", ".woff2", ".ttf", ".eot", ".otf"];
            var pathname = new URL(timing.name).pathname.toLowerCase();
            return fontExtensions.some(fontExt => pathname.endsWith(fontExt));
        }
        catch { }

        return false;
    }

    isThirdParty(urlString: string): boolean {
        try {
            var url = new URL(urlString);
            return url.origin !== self.location.origin;
        }
        catch { }

        return false;
    }

    sentPageResources() {
        this.hasSent = true;
        ResourceService.cachedResourceTimings = null;
    }
}
