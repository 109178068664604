import { Logger } from "./Logger";
import { isError, patch, roundToDecimal, serialize } from "./utils";

const MIN_ENTRIES_FOR_SEND = 1;

class _ErrorObserver {

  private errorEntries: RM.ErrorEntry[] = [];
  private options: RM.InstallOptions;

  install(options: RM.InstallOptions) {
    this.options = options;

    this.watchGlobal();
    this.watchPromise();
    this.wrapConsole();
  }

  getErrors(lastChance = false): RM.ErrorEntry[] {
    if (lastChance || this.errorEntries.length >= MIN_ENTRIES_FOR_SEND) {
      var result = this.errorEntries;
      this.errorEntries = [];
      return result;
    }
    return [];
  }

  addError(error: RM.ErrorEntry): void {
    if (error.message == null || error.message.toString().indexOf("Script error") === 0) {
      return;
    }

    this.errorEntries.push(error);
  }

  private watchGlobal() {
    self.addEventListener("error", (errorEvent : ErrorEvent) => {
      try {
        if (!errorEvent || !errorEvent.error) {
          return;
        }

        ErrorObserver.addError({
          name: errorEvent.error.name,
          message: errorEvent.error.message,
          stack: errorEvent.error.stack,
          cause: errorEvent.error.cause ? serialize(errorEvent.error.cause) : undefined,
          time: roundToDecimal(performance.now()),
          entry: "global",
          pageUrl: self.location.toString()
        });
      }
      catch(e) {
        Logger.error(e, "global error handler");
      }

    });
  }

  private watchPromise() {
    self.addEventListener('unhandledrejection', function(evt) {
      try {
        if (!evt) {
          return;
        }

        var reason = evt.reason;
        if (reason === undefined || reason === null) {
          return;
        }

        if (!isError(reason)) {
          reason = new Error(serialize(reason));
        }

        ErrorObserver.addError({
          name: reason.name,
          message: reason.message,
          stack: reason.stack,
          cause: reason.cause ? serialize(reason.cause) : undefined,
          time: roundToDecimal(performance.now()),
          entry: "promise",
          pageUrl: self.location.toString()
        });
      }
      catch (e) {
        Logger.error(e, "promise error handler");
      }
    });

  }

  private wrapConsole() {
    patch(self.console, 'error', function (original) {
      return function (/* ...args */) {
        try {
          var args = Array.prototype.slice.call(arguments);

          var error;
          if (args.length === 1 && isError(args[0])) {
            error = args[0];
          }
          else {
            error = new Error((args.length === 1) ? serialize(args[0]) : serialize(args));
          }

          ErrorObserver.addError({
            name: error.name,
            message: error.message,
            stack: error.stack,
            cause: error.cause ? serialize(error.cause) : undefined,
            time: roundToDecimal(performance.now()),
            entry: "console",
            pageUrl: self.location.toString()
          });
        }
        catch (e) {
          Logger.error(e, "console error handler");
        }

        return original.apply(this, arguments);
      }
    });
  }

}

export const ErrorObserver = new _ErrorObserver();